import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { Helmet } from "react-helmet";

// markup
const contactPage = () => {
  return (
    <div>
      <Navbar />
      <main>
        <Helmet>
          <meta charSet="utf-8" />
          <meta name="description" content="Personal Site - Joko Purnomo" />
          <meta
            name="keywords"
            content="personal site,joko purnomo,Joko Purnomo,portfolio"
          />
          <meta name="author" content="Joko Purnomo" />
          <title>Contact | Joko Purnomo</title>
        </Helmet>

        <div className=" bg-white my-16">
          <div className="max-w-4xl mx-auto px-4 sm:px-6">
            <h1 className="text-3xl font-bold">Contact Joko Purnomo</h1>

            <p className="mt-5 text-2xl">
              Untuk menghubungi saya, silahkan kirimkan email ke&nbsp;
              <a
                href="mailto:jokoganss07@gmail.com"
                className="text-blue-600 underline "
              >
                jokoganss07@gmail.com
              </a>
              . Email tersebut saya gunakan untuk menerima semua email masuk
              yang khusus hanya yang berhubungan dengan saya langsung.
            </p>
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
};

export default contactPage;
